import React from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Home from './Home';
import { Container } from 'react-bootstrap';

function App() {
  return (    
      <Home />
  );
}

export default App;
