import React, { Component } from "react"
import { Button, Container, Jumbotron } from "react-bootstrap";
import "./Home.css";
import ReactDOM from "react-dom";

const imgMassawa = require('./assets/massawa.jpg');
const wrapStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: `url(${imgMassawa})`,
  backgroundSize: 'cover'
};

const contentStyle = {
  width: '100vw',
  height: '100vh',
  background: 'rgba(255,255,255,0.3)',
}

const iframe = '<iframe height="10000" style="width: 100%;" scrolling="no"src="https://belaige4.wixsite.com/website/" frameborder="no" allowtransparency="false" allowfullscreen="false"></iframe>'; 

function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}
class Home extends Component {

  render() {
    return (
      <div className="App">
      <Iframe iframe={iframe} />,
    </div>
    );
  }
}

export default Home;